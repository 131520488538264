<template>
    <b-container :class="[isMobile ? 'mt-5 pt-3' : 'mt-3' ]">
        <div v-if="isLoading" class="d-flex flex-column justify-content-center overlay">
            <div class="loadingText">
                <div class="h1 text-center">Loading</div>
                <div class="dot-elastic"></div>
            </div>
            
            <b-progress variant="success" striped :animated="true" class="w-50 mx-auto">
                <b-progress-bar :value="pdfProgressAmt">
                    <span>Loading - Progress: <strong>{{ pdfProgressAmt }}%</strong></span>
                </b-progress-bar>
            </b-progress>
        </div>

        <b-button v-if="!isMobile" variant="primary" @click="goBack">
           <font-awesome-icon icon="fa-chevron-left"></font-awesome-icon> Back
        </b-button> 
        <b-button variant="outline-dark" @click="print" class="ml-1">
            <font-awesome-icon icon="fa-solid fa-print" />
        </b-button>
        <b-button class="ml-1" variant="outline-dark" @click="download" >
            <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" />
        </b-button>
        <b-button class="ml-1 mr-2" variant="outline-dark" @click="email" >
            <font-awesome-icon icon="fa-regular fa-envelope" />
        </b-button>

        <br v-if="isMobile" />
        <div v-show="!isLoading" :class="[isMobile ? 'h6 d-inline align-middle': 'h5 d-inline align-middle']">
            File name: {{contractName}}
        </div>
        
        <div>
            <vue-pdf-embed 
                :source="pdfSource" 
                @progress="pdfProgress" 
                @rendered="handleDocumentRender" 
                ref="pdfRef">
            </vue-pdf-embed>
        </div>  
        
    </b-container>
</template>

<script>
import { format, getUnixTime } from 'date-fns'
import { fromUnixTime } from 'date-fns'
//import { addWeeks } from 'date-fns'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import numbro from 'numbro'
import print from 'print-js'
import Browser from '@/config/browser.js'
import fileDownload from 'js-file-download'

export default {
    name: 'viewContract',
    components: {
        VuePdfEmbed
    },
    computed: {
        id () {
            return this.$route.params.id
        },
        userInfo() {
          return this.$state.userInfo
        },
        isMobile() {
            return this.$state.isMobile
        }
    },
    data() {
        return {
           contract: {},
           money: {},
           pdfSource: "",
           printSrc: null,
           contractName: "",
           emailed: false,
           printed: false,
           downloaded: false,
           isLoading: true,
           pdfProgressAmt: 0,
           pageCount: 1
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        pdfProgress(data) {
            //console.log(data)
            let fileSize = data.total
            let progress = data.loaded

            let percentComplete = (progress*100)/fileSize
            //console.log(`PercentComplete is: ${percentComplete}`)
            this.pdfProgressAmt = percentComplete
        },
        handleDocumentRender() {
            this.isLoading = false
            this.pageCount = this.$refs.pdfRef.pageCount
        },
        print() {
            this.printed = true
            if( Browser.isChrome() ){
                console.log(`Browser is NOT safari...pritn directly`)
                print(this.printSrc)
            } else {
                console.log(`Browser is safari...redirecting to new tab`)
                window.location.href = this.printSrc   
            }
            
            let log = {
                logTime: getUnixTime(new Date()),
                        logMsg: `Contract was Printed. Filename: ${this.contractName}`,
                        job: this.id,
                        logUser: this.userInfo.name,
            }
            //logit        
            this.$actions.log(log)
        },
        async download() {
            this.downloaded = true
            let fileData = {
                name: this.contractName,
                contractId: this.contract.contractId,
                type: "contract"
            }

            try {
                const res = await this.$axios.put(`/download`, fileData, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                    responseType: 'blob'
                })
                console.log(res)
                fileDownload(res.data, fileData.name)

                let log = {
                    logTime: getUnixTime(new Date()),
                    logMsg: `Contract was downloaded. Filename: ${this.contractName}`,
                    job: this.id,
                    logUser: this.userInfo.name,
                }
                //logit        
                this.$actions.log(log)

            } catch (err) {
                console.log("Downlaod Error", err)
            }
        },
        async email() {
            //route to email page with the info needed to create a link to the file.
            const file = this.contractName
            const contractId = this.contract.contractId
            this.emailed = true

            this.$router.push(
                {
                    name: 'email', 
                    params: { 
                        customer : this.contract.customer._id, 
                        job: this.contract._id, 
                        email: this.contract.customer.emails.primary,
                        cc:  this.contract.customer.emails.secondary,
                        fname: this.contract.customer.fname,
                        contractLink: {
                            file: file,
                            contractId: contractId
                        }
                    } 
                }
            )

        },
        async makeTemplete() {
            console.log(`makeTemplete Called...id is: ${this.$route.params.id}`)

            try {
                const res = await this.$axios.get(`/job/${this.id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })

                const moneyRes = await this.$axios.get(`/money/job/${this.id}`, {
                    headers: { Authorization: `Bearer ${this.$state.token}` }
                })
                //formatDates
                let contractDate = format(fromUnixTime(res.data.dates.contractDate), "MM/dd/yyyy")
                //let contractStartDate = format(addWeeks(fromUnixTime(res.data.dates.contractDate),4), "MM/dd/yyyy")
                //let contractEndDate = format(addWeeks(fromUnixTime(res.data.dates.contractDate),9), "MM/dd/yyyy")
                res.data.dates.contractDate = contractDate
                //res.data.dates.contractStartDate = contractStartDate
                //res.data.dates.contractEndDate = contractEndDate
                this.contract = res.data
                
                //add up money and get some totals
                let money = moneyRes.data
                console.log("Money is:")
                console.log(money)

                const contractDebits = money.filter(lineItem => lineItem.debit.toLowerCase() === 'debit')
                const contractTotal = contractDebits.reduce((sum, lineItem) => {
                    let newSum = sum + lineItem.amount
                    return newSum
                }, 0)
                console.log(`Contract total is: ${contractTotal}`)

                const deposit = this.contract.deposit
                const progressPayment = this.contract.progressPayment ? this.contract.progressPayment : 0
                const balance = contractTotal - deposit - progressPayment
                
                const contractMoney = {
                    total: numbro(contractTotal).format(),
                    deposit: numbro(deposit).format(),
                    progressPayment: numbro(progressPayment).format(),
                    balance: numbro(balance).format()
                }                
                console.log(`Contract data loaded.`)
                this.money = contractMoney

                this.genPDF(this.contract, this.money, this.contract.contractId)

            } catch (err) {
                console.error(err)
            }
        },
        async genPDF(contract, money, contractId){
            console.log(`genPDF Called...`)
            
            let payload = {
                contractId: contractId,
                money: money,
                contract: contract
            }

            try {
                const res = await this.$axios.post(`/pdf/contract`, payload, {
                        headers: { Authorization: `Bearer ${this.$state.token}`,
                        responseType: "blob" 
                    }
                })
                
                console.log(res)
                console.log(`GenPDF returned`)
                
                if(res.status == 200){

                    //console.log(res.data)
                    let filePath = res.data.filename
                    this.contractName = filePath.substring(filePath.lastIndexOf('/')+1)
                    this.pdfSource = `${this.$baseUrl}/files/${contractId}/contracts/${this.contractName}`
                    this.printSrc = `${this.$baseUrl}/files/${contractId}/contracts/${this.contractName}`
                    
                    //logit
                    let log = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Contract was viewed: ${this.contractName}`,
                        job: this.id,
                        logUser: this.userInfo.name,
                        addInfo: `File path: ${res.data.filename}`
                    }
                    this.$actions.log(log)
                    /*
                    this.src = pdf.createLoadingTask(`${this.$baseUrl}/files/${contractId}/contracts/${this.contractName}`)
                    this.printSrc = `${this.$baseUrl}/files/${this.contract.contractId}/contracts/${this.contractName}`
                    
                    this.src.then(pdf => {
                        this.numPages = pdf.numPages
                    })
                
                    //logit
                    let log = {
                        logTime: getUnixTime(new Date()),
                        logMsg: `Contract was viewed: ${this.contractName}`,
                        job: this.id,
                        logUser: this.userInfo.name,
                        addInfo: `File path: ${res.data.filename}`
                    }
                    this.$actions.log(log)
                    */
                } else {
                    console.err("Something went wrong...")
                }

            } catch (err) {
                console.error(err)
            }
        }
    },
    created() {
        numbro.setDefaults({
            thousandSeparated: true,
            mantissa: 2
        })

        if(Browser.isChrome()){
            console.log(`Browser is Chrome`)
        } else if(Browser.isSafari()) {
            console.log(`Browser is Safari`)
        } else {
            console.log("I dont know what browser this is...")
        }

        this.makeTemplete()
    },
    async beforeDestroy(){
        //was file printed, emailed or downloaded? if not you can delete this file
        if(this.emailed || this.downloaded || this.printed) {
            console.log ("Contract was used for email, print, or download. Saving file.")
        } else {

            let payload = {
                contractId: this.contract.contractId,
                filename: this.contractName,
                path: "contracts"
            }
            console.log("This contract was never used. Delete file.")
            
            try {
                const res = await this.$axios.post(`/pdf/delete`, payload, {
                        headers: { Authorization: `Bearer ${this.$state.token}` 
                    }
                })
                console.log(res)

            } catch (err) {
                console.error(err)
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
    z-index: 2;
}
.vue-pdf-embed {
    margin-bottom: 8px;
    margin-top:2em;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.loadingText {
    display: flex;
    justify-content: center;
}
.dot-elastic {
  margin-left: 1.5em;
  margin-bottom: 1em;
  align-self: flex-end;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #111;
  color: #111;
  animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before, .dot-elastic::after {
  content: "";
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #111;
  color: #111;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #111;
  color: #111;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>